<template>
  <!-- 内容 -->
  <div class="help_content">
    <div class="help_content_bg" style="display: flex; justify-content: space-between">
      <div class="help_content_top" style="cursor: pointer;" @click="$router.push('./helpOpenTutorial')">
        <div style="padding-left: 50px;display: inline-flex; flex-direction: column; justify-content: center;height: -webkit-fill-available">
          <img style="width: 70px; height: 70px" src="../../assets/img/help/new_user_tutorial.png">
          <span style="color: #464255; font-weight: 700; font-size: 20px;margin-top: 30px">新手教程</span>
          <span style="color:#A3A3A3; font-weight: 500; font-size: 16px;margin-top: 10px">了解在UPlus如何开启加密货币交易</span>
        </div>
      </div>
      <div class="help_content_top help_content_top_left" style="cursor: pointer;" @click="feedback()">
        <div style="padding-left: 50px;display: inline-flex; flex-direction: column; justify-content: center;height: -webkit-fill-available">
          <img style="width: 65px; height: 70px" src="../../assets/img/help/customer_service.png">
          <span style="color: #464255; font-weight: 700; font-size: 20px;margin-top: 30px">客服</span>
          <span style="color:#A3A3A3; font-weight: 500; font-size: 16px;margin-top: 10px">无法解决，联系人工客服</span>
        </div>
      </div>
      <div class="help_content_top help_content_top_left" style="cursor: pointer;" @click="$router.push('./helpCommonProblem')">
        <div style="padding-left: 50px;display: inline-flex; flex-direction: column; justify-content: center;height: -webkit-fill-available">
          <img style="width: 65px; height: 70px" src="../../assets/img/help/common_problem.png">
          <span style="color: #464255; font-weight: 700; font-size: 20px;margin-top: 30px">常见问题</span>
          <span style="color:#A3A3A3; font-weight: 500; font-size: 16px;margin-top: 10px">Uplus使用中常见问题的说明</span>
        </div>
      </div>
    </div>


    <div style="display: flex; justify-content: space-between; margin-top: 30px;align-items: flex-end;">
      <span class="content_title" style="margin-top: 0">常见问题</span>
      <span style="display: block; font-size: 16px !important;color: #A3A3A3;cursor: pointer" @click="$router.push('./helpCommonProblem')">查看全部></span>
    </div>

    <div class="content_list" style="margin-top: 30px">
      <span style="display: block;margin-bottom: 30px;cursor: pointer; vertical-align: middle" v-for="(item, i) in common_problem" @click="goto(item)">{{i+1}}. {{ item.title }} >><img style="display: inline-block;margin-left: 10px;vertical-align: middle;width: 13px; height: 17px;" :style="{width: item.index==0?'17px':'13px'}" :src="item.index==0?commonProblemRemindIcon:item.index!=0&&item.index>2?commonProblemHotIcon:''"/></span>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 30px;align-items: flex-end;">
      <span class="content_title" style="margin-top: 0">公告</span>
      <span style="display: block; font-size: 16px !important;color: #A3A3A3;cursor: pointer" @click="gotoNoticePage()">查看全部></span>
    </div>
    <div class="notice_list" style="margin-top: 30px">
      <div v-for="(item, i) in noticeMsgList" style="margin-bottom: 30px;cursor: pointer;text-align: left" @click="gotoNotice(item)">
        <span style="display: block">{{ item.title }}</span>
        <span style="display: block !important;margin-top: 8px;color: #72777E;">{{ item.send_time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getNoticeMsgList } from "../../server/helper.js"
export default {
  name: "help_content_one",
  components: {},
  data(){
    return{
      common_problem: [
        {index: 0, title: '新手必读'},
        {index: 1, title: 'UPlus产品介绍'},
        {index: 2, title: '专属用户介绍'},
        {index: 6, title: '修改策略仓位说明'},
        {index: 7, title: '止损说明' },
        {index: 9, title: '点卡和燃料费说明' },
        // {index: 10, title: '交易账户浮亏说明' },
      ],

      noticeMsgList: [],
      token: '',
      commonProblemHotIcon: require('../../assets/img/help/hot.png'),
      commonProblemRemindIcon: require('../../assets/img/help/remind.png'),
      isMobile: false
    }
  },

  created() {
    this.$store.state.showFeedBack = false
    if (this.$util._isMobile()){
      this.isMobile = true
    }
  },

  mounted() {
    this.token = localStorage.getItem('token')
    this.getNoticeMsgList()
  },

  methods: {
    goto(item){
      this.$router.push('/helpCommonProblem?problemId=problem'+item.index)

    },

    gotoNoticePage(){
      if (this.token){
        this.$router.push('/helpNoticeAll')
      } else {
        this.$router.push('/helpNoticeList')
      }
    },

    gotoNotice(item) {
      this.$router.push('helpNoticeContent?home=home'+'&noticeId=' + item.id)
    },

    getNoticeMsgList(){
      getNoticeMsgList().then(res =>{
        if(!res) return
        if(res.success){
          if (res.data.list){
            for (var i = 0; i < res.data.list.length; i++) {
              if (i<=5){
                this.noticeMsgList.push(res.data.list[i])
              }
            }
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    feedback(){
      if(this.isMobile){
        this.$router.push('/messageBack')
      } else {
        if (this.$route.path.indexOf('/user_center') > -1 || this.$route.path.indexOf('/robot') > -1 || this.$route.path.indexOf('/rebate') > -1 || this.$route.path.indexOf('/user') > -1){
          this.$store.state.showFeedBack = true
        } else {
          this.$store.state.showFeedBack = true
          if(!localStorage.getItem('token')){
            this.$router.push('./')
          } else {
            this.$router.push('./center')
          }
        }
      }
    },
  }
}
</script>

<style scoped>
span{
  color: #1E2329;
}

@media screen and (max-width: 900px){
  .help_content {
    /*width: 100%;*/
    margin-top: -27px;
    min-height: 450px;
    padding: 0 16px
  }
  .content_list {
    column-count: 1;
  }
  .content_list span{
    color: #464255;
    font-size: 14px;
  }
  .content_title {
    font-size: 24px;
    font-weight: 500;
    margin-top: 30px;
    display: block;
  }
  .auto_list{
    column-count: 3;
    text-align: center;
  }
  .notice_list{
    column-count: 1;
    text-align: center;
  }
  .notice_list span:nth-child(1){
    font-size: 14px;
  }
  .notice_list span:nth-child(2){
    font-size: 12px;
  }
  .el-container{
    display: block;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 90%;
    height: 230px;
    text-align: center;
  }

  .help_content_top_left{
    margin-top: 20px;
  }
  .help_content_bg{
    /*text-align: center;*/
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    height: -webkit-fill-available;
  }
  .help_content_bg div{
    /*text-align: center;*/
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 230px !important;
    padding: 0 !important;
  }
}
@media screen and (min-width: 900px){
  .content_list {
    /*column-count: 4;*/
  }

  .content_list span{
    display: inline-block !important;
    color: #464255;
    width: 30%;
  }

  .auto_list{
    column-count: 6;
    text-align: center;
  }

  .notice_list{
    /*column-count: 2;*/
    /*text-align: center;*/
  }

  .notice_list div{
    display: inline-block !important;
    width: 50%;
  }

  .notice_list span:nth-child(1){
    font-size: 16px;
  }
  .notice_list span:nth-child(2){
    font-size: 14px;
  }

  .help_content {
    width: 1200px;
    min-height: 795px;
    margin: 50px auto 0;
    padding: 0 16px
  }

  .content_title {
    display: block;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 380px;
    height: 260px;
  }

  .help_content_top_left{
    margin-left: 30px;
  }

  .help_content_bg{
    text-align: unset;
  }
}
</style>
